import * as React from 'react';
import { useLocale } from '../../common/hooks/useLocale';

export const Quotation: React.FC = ({ children }) => {
  const locale = useLocale();

  if (locale === 'de-DE') {
    return <>&bdquo;{children}&rdquo;</>;
  }

  if (locale === 'fr-FR') {
    return <>&laquo; {children} &raquo;</>;
  }

  if (locale === 'ja-JP') {
    return <>「{children}」</>;
  }

  if (locale === 'es-ES') {
    return <>&laquo;{children}&raquo;</>;
  }

  if (locale === 'es-LA') {
    return <>&laquo;{children}&raquo;</>;
  }

  if (locale === 'it-IT') {
    return <>&laquo;{children}&raquo;</>;
  }

  return <>&ldquo;{children}&rdquo;</>;
};
